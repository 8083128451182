export const ProductStage: {
  conceptual: string;
  available: string;
  pre_sell: string;
  crowdfunding: string;
} = {
  conceptual: "概念",
  available: "现货",
  pre_sell: "预售",
  crowdfunding: "站外售卖"
};

export const ProductStatus: {
  pending: string;
  launch: string;
} = {
  pending: "已下架",
  launch: "已上架"
};

export const OrderStatus: {
  unpaid: string;
  paid: string;
  closed: string;
} = {
  unpaid: "未支付",
  paid: "已付款",
  closed: "已关闭"
};

export const SubOrderStatus: {
  unpaid: string;
  paid: string;
  shipping: string;
  confirmed: string;
  closed: string;
  refunding: string;
  refunded: string;
  after_sale_refunding: string;
  after_sale_refunded: string;
} = {
  unpaid: "未支付",
  paid: "已付款待发货",
  shipping: "已发货待收货",
  confirmed: "已收货",
  closed: "已关闭",
  refunding: "未发货退款中",
  refunded: "未发货已退款",
  after_sale_refunding: "售后退款中",
  after_sale_refunded: "售后已退款"
};

export const RefundOrderStatus: {
  pending: string;
  processing: string;
  accepted: string;
  rejected: string;
} = {
  pending: "未开始",
  processing: "进行中",
  accepted: "已完成",
  rejected: "已拒绝"
};

export const ShippingStatus: {
  unpaid: string;
  paid: string;
  refunding: string;
  refunded: string;
  closed: string;
} = {
  unpaid: "未支付",
  paid: "已付运费",
  refunding: "退运费中",
  refunded: "已退运费",
  closed: "已关闭"
};

export const Currencies: {
  cny: string;
  usd: string;
} = {
  cny: "人民币",
  usd: "美元"
};

export const CouponCategory: {
  special_offer: string;
  early_bird: string;
} = {
  special_offer: "优惠券",
  early_bird: "必购码"
};

export const CouponType: {
  save: string;
  discount: string;
  early_bird: string;
} = {
  save: "满减",
  discount: "折扣",
  early_bird: "必购"
};

export const CouponValidityDateType: {
  fixed: string;
  since_today: string;
} = {
  fixed: "指定时间段内有效",
  since_today: "领取后数日内有效"
};

export const CouponValidityGoodsType: {
  all: string;
  product: string;
} = {
  all: "全部商品可用",
  product: "指定商品可用"
};

export const CouponActivityType: {
  public: string;
  private: string;
} = {
  public: "公开领取",
  private: "发码兑换"
};

export const CouponCodeStatus: {
  unused: string;
  claimed: string;
  canceled: string;
} = {
  unused: "未兑换",
  claimed: "已兑换",
  canceled: "已失效"
};

export const CouponCodeSendFlag: {
  system: string;
  manual: string;
} = {
  system: "已系统批量发送",
  manual: "已人工单独发送"
};

export const CouponCodeSendErrorType: {
  not_found: string;
  user_not_found: string;
  used: string;
  update_error: string;
} = {
  not_found: "券码未找到",
  user_not_found: "用户未找到",
  used: "券码状态不是未兑换，或已有发送标记",
  update_error: "数据更新失败"
};

export const CouponStatus: {
  unused: string;
  used: string;
  locked: string;
  canceled: string;
} = {
  unused: "未使用",
  used: "已使用",
  locked: "锁定中",
  canceled: "已作废"
};

export const WWWWProductStatus: {
  developing: string;
  GB: string;
  inStock: string;
  restock: string;
  soldOut: string;
  jump: string;
} = {
  developing: "研发",
  GB: "预售",
  inStock: "现货",
  restock: "补货",
  soldOut: "售罄&想要",
  jump: "站外跳转"
};
